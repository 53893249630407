import { ColorShade } from '@/components/atoms/Colors/Colors';
import Icon, { IIcon } from '@/components/atoms/Icon/Icon';
import { MouseEventHandler, useState } from 'react';

/**
 * IButtonWithIcon Interface for the ButtonWithIcon Component
 *
 * @interface
 */
export interface IButtonWithIcon {
  /** The text to display. */
  children: string;
  /** The icon to display. */
  icon: IIcon;
  /** The onClick event handler. */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** If the button is disabled. */
  disabled?: boolean;
  /**
   * The optional classes to apply to the button.
   *
   * @default ''
   */
  classes?: string;
}

/**
 * Icon State Styles This is the styles for the icon
 *
 * @constant
 */
export const iconStateStyles: {
  /** Color Map of the icon states */
  color: {
    [key: string]: string;
  };
  /** Shade Map of the icon states */
  shade: { [key: string]: ColorShade };
} = {
  color: {
    hovered: 'base',
    normal: 'neutral',
    disabled: 'neutral',
  },
  shade: {
    hovered: 300,
    normal: 800,
    disabled: 500,
  },
};

/**
 * Button With Icon Button with Icon is used to display a button with an icon
 *
 * @param {IButtonWithIcon} props - The props for the Button With Icon component
 * @returns {React.FC<IButtonWithIcon>} Button With Icon Component
 */
const ButtonWithIcon: React.FC<IButtonWithIcon> = ({
  children,
  icon,
  onClick,
  disabled,
  classes,
}) => {
  const [hovered, setHovered] = useState(false);
  // Set the icon color default to normal
  let iconColor = iconStateStyles.color.normal;
  let iconShade = iconStateStyles.shade.normal;
  // Determine if the button is hovered or disabled since a disabled button can not also be hovered
  if (disabled) {
    iconColor = iconStateStyles.color.disabled;
    iconShade = iconStateStyles.shade.disabled;
  } else if (hovered) {
    iconColor = iconStateStyles.color.hovered;
    iconShade = iconStateStyles.shade.hovered;
  }

  const disabledClass = disabled
    ? 'text-neutral-500 cursor-not-allowed group-hover:text-neutral-500'
    : 'text-neutral-800';

  const testId = `button-with-icon-${hovered}-${disabled}`;
  return (
    <button
      data-testid={testId}
      onClick={onClick}
      type="button"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      disabled={disabled}
      className={`inline-flex items-center rounded-sm focus-visible:ring-focus-400 ring-0 focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:outline-0 focus-visible:outline group text-body5 ${classes}`}
    >
      <Icon
        {...icon}
        colorType={iconColor}
        colorShade={iconShade}
        classes={disabledClass}
      />
      <span className={`pl-1.5 group-hover:text-base-300 ${disabledClass}`}>
        {children}
      </span>
    </button>
  );
};

ButtonWithIcon.defaultProps = {
  classes: '',
};

export default ButtonWithIcon;
