import { gridMaxWidth } from '@/components/atoms/Grid/GridClasses';
import { IIcon } from '@/components/atoms/Icon/Icon';
import LostLogo from '@/components/atoms/LostLogo/LostLogo';
import AnimatedIcon from '@/components/molecules/AnimatedIcon/AnimatedIcon';
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon/ButtonWithIcon';
import DropDownMenu, {
  IDropDownIcon,
  IMenuButton,
  IMenuItem,
} from '@/components/molecules/DropDownMenu/DropDownMenu';
import NavSideBar from '@/components/molecules/NavSideBar/NavSideBar';
import NavTopBar from '@/components/molecules/NavTopBar/NavTopBar';
import PersistentBannerHandler from '@/components/organisms/PersistentBannerHandler/PersistentBannerHandler';
import { defaultEventEmitter } from '@/lib/analytics/commonEmitter';
import { flagPersistentSearch } from '@/lib/constants/constants/featureFlags';
import useFeatureFlags from '@/lib/hooks/featureFlags/useFeatureFlags';
import { useShelterMultiUserFlag } from '@/lib/hooks/featureFlags/useShelterMultiUserFlag';
import useMe from '@/lib/hooks/me/useMe';
import { usePersistentBannerContext } from '@/lib/hooks/persistentBanner/PersistentBanner';
import { useShelterLocationContext } from '@/lib/hooks/shelterDashboard/ShelterLocation';
import getBasePath from '@/lib/utils/getBasePath/getBasePath';
import { getIsShelterAdmin } from '@/lib/utils/helpers/shelterDashboard/isShelterAdmin';
import { getShelterDashDropdownLinks } from '@/lib/utils/helpers/shelterDashboard/navigation';
import Link from 'next/link';
import React from 'react';
import Notifications from './sections/Notifications';

/**
 * Link Type For Navbar
 *
 * @typedef LinkType - The link type for the navbar.
 */
export type LinkType = {
  /** The url of the link. */
  url: string;
  /** The name of the link. */
  name: string;
  /** The optional active state of the link. */
  active?: boolean;
  /** The optional target of the link. */
  target?: string;
  /** The optional element to render instead of a link. */
  icon?: IIcon;
  /** The optional classes to add to the link. */
  classes?: string;
  /** The optional classes to add to the icon button. */
  buttonClasses?: string;
};

/**
 * Links Links to be used in the navbar.
 *
 * @constant
 */
const links = [
  {
    name: 'Lost Pet Tips',
    url: '/lost-tips',
  },
  {
    name: 'Found Pet Tips',
    url: '/found-tips',
  },
  {
    name: 'How to Help',
    url: '/how-to-help',
  },
  {
    name: 'About',
    url: '/about',
  },
];

/**
 * Generate Family Links - Links to be used in the navbar for family sites.
 *
 * @param {'top' | 'aside'} variant - Where the links are going to be used.
 * @returns {LinkType[]} - The link types
 */
function generateFamilyLinks(variant: 'top' | 'aside') {
  const sizeMap = {
    top: 20,
    aside: 24,
  };

  const classesMap = {
    top: '',
    aside: 'mt-8',
  };

  const buttonClasses = {
    top: '!text-[12px]',
    aside: '!text-[16px]',
  };

  const familyLinks: LinkType[] = [
    {
      name: 'Petco Love',
      url: 'https://petcolove.org',
      target: '_blank',
    },
    {
      name: 'Petco Love Adopt',
      url: 'https://petcolove.org/adopt/',
      target: '_blank',
    },
    {
      name: 'Petco Love Care',
      url: 'https://petcolove.org/care/',
      target: '_blank',
    },
    {
      url: '/spanish',
      name: 'Español',
      icon: {
        icon: 'globe',
        size: sizeMap[variant],
        colorType: 'black',
        colorShade: 100,
      },
      classes: classesMap[variant],
      buttonClasses: buttonClasses[variant],
    },
  ];

  return familyLinks;
}

/**
 * IDesktopNavLinks Interface for the Desktop Navigation Links
 *
 * @interface
 */
interface IDesktopNavLinks {
  /** The links to display in the desktop navigation. */
  links: LinkType[];
}

/**
 * Desktop Nav Links Desktop Navigation Links Component used to display out the
 * links for the desktop navigation.
 *
 * @param {IDesktopNavLinks} props - The props for the DesktopNavLinks
 *   component.
 * @returns {React.FC<IDesktopNavLinks>} DesktopNavLinks Component
 */
const DesktopNavLinks: React.FC<IDesktopNavLinks> = ({ links }) => {
  return (
    <ul className="flex items-center flex-row h-16 space-x-8 ">
      {links.map((link) => (
        <li key={link.name} className="h-full flex items-center">
          <Link href={link.url}>
            <a
              className={`text-body4 font-bold border-b-4 border-invisible hover:border-b-4 hover:border-base-300 hover:border-solid flex items-center h-full transition-all duration-400`}
            >
              {link.name}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  );
};

/**
 * INavbar Interface for Navbar component.
 *
 * @interface
 */
export interface INavbar {
  /** If the user is logged in */
  loggedIn?: boolean;
  /** If the user has a message */
  hasMessage?: boolean;
  /** Is the user a shelter */
  isShelter?: boolean;
  /** Should this be a minimal navbar */
  minimal?: boolean;
  /** User type */
  isFetchingUser?: boolean;
}

/**
 * Navbar Used to display the navigation bar.
 *
 * @param {INavbar} props - The props for the Navbar component.
 * @returns {React.FC<INavbar>} Navbar Component
 */
const Navbar: React.FC<INavbar> = ({
  loggedIn,
  hasMessage,
  isShelter = false,
  minimal = false,
  isFetchingUser,
}: INavbar) => {
  const { setBannerData } = usePersistentBannerContext();
  const { me } = useMe();

  const { selectedShelterId } = useShelterLocationContext();

  const isShelterAdmin = getIsShelterAdmin(me, selectedShelterId);

  /** This will perform any tasks need on logout */
  const signOutHandler = () => {
    /** This is the event data being sent */
    const eventData = {
      name: 'logout',
      data: {
        user_type: isShelter ? 'Shelter User' : 'Consumer User',
        user_id: me?.user?.id,
      },
    };

    /** This will clear the user id tracking events in google */
    defaultEventEmitter.handleEvent(eventData);

    /** Reset the Banner Data */
    setBannerData();

    window.location.href = `${getBasePath()}/api/auth/logout-flow/`;
  };

  const { type: showingBanner } = usePersistentBannerContext();

  return (
    <>
      {!minimal && <NavTopBar links={generateFamilyLinks('top')} />}
      <nav
        className="bg-neutral-100 sticky top-0 z-[22] h-[60px] md:h-16 font-petco"
        data-testid="navbar"
      >
        <div
          className={`h-full flex justify-between items-center ${gridMaxWidth} grid grid-cols-5`}
        >
          <div className="col-span-3 md:col-span-1 flex items-center py-3.5">
            <NavSideBar
              classes="flex items-center md:hidden mr-2"
              bottomLinks={generateFamilyLinks('aside')}
              mainLinks={links}
            />
            <div className="flex items-center w-auto h-auto max-w-[134px] md:max-w-[200px]">
              <LostLogo variant="small" />
            </div>
          </div>

          <div
            className="md:col-span-3 hidden md:flex justify-center items-center"
            id="navbar-default"
          >
            {!minimal && <DesktopNavLinks links={links} />}
          </div>
          <div className="col-span-2 md:col-span-1 flex justify-end items-center">
            {isFetchingUser ? (
              <div className="w-16 h-5 bg-neutral-300 rounded-lg animate-pulse"></div>
            ) : (
              <DashboardLinks
                signOutHandler={signOutHandler}
                loggedIn={loggedIn}
                isShelter={isShelter}
                hasMessage={hasMessage}
                isShelterAdmin={isShelterAdmin}
              />
            )}
          </div>
        </div>
        <PersistentBannerHandler />
      </nav>
      {/* This is a spacer for when the persistent banner is showing */}
      {showingBanner && <div data-testid="banner-margin-div" className="h-8" />}
    </>
  );
};

export default Navbar;

/**
 * IDashboardLinks Interface for the Inner component
 *
 * @interface
 */
interface IDashboardLinks {
  /** Function that handles logging out */
  signOutHandler: () => void;
  /** Whether the user is logged in or not */
  loggedIn?: boolean;
  /** Whether the user is a shelter user */
  isShelter: boolean;
  /** Whether the user has messages */
  hasMessage?: boolean;
  /** Whether the user is a shelter admin. */
  isShelterAdmin?: boolean;
}

/**
 * Houses the dashboard links of the NavBar
 *
 * @param {IDashboardLinks} props - The props for the Inner component
 * @returns {React.ReactNode} - The Inner component
 */
function DashboardLinks({
  signOutHandler,
  loggedIn,
  isShelter,
  hasMessage,
  isShelterAdmin,
}: IDashboardLinks) {
  const { isFlagEnabled } = useShelterMultiUserFlag();
  const { featureFlags } = useFeatureFlags();

  /**
   * User Links Links to be used in the dropdown menu for logged in users
   *
   * @constant
   */
  const userLinks: Array<IMenuItem | IMenuButton> = [
    {
      name: 'Pet Dashboard',
      url: '/dash',
    },
    { name: 'Settings', url: '/dash/info' },
    {
      name: 'Sign Out',
      onClick: signOutHandler,
    },
  ];

  /**
   * Shelter Links to be used in the dropdown menu for logged in shelters
   *
   * @constant
   */
  const shelterLinks: Array<IMenuItem | IMenuButton> = [
    ...getShelterDashDropdownLinks({
      isAdmin: isShelterAdmin,
      variation: isFlagEnabled ? 'multiUser' : 'original',
    }),
    { name: 'Sign Out', onClick: signOutHandler },
  ];

  const dropDownIcon: IDropDownIcon = {
    icon: 'userCircle',
    size: 24,
    colorType: 'neutral',
    colorShade: 800,
    activeColorShade: 300,
    activeColorType: 'base',
  };

  return (
    <div className="flex flex-row gap-3">
      {!loggedIn ? (
        <a
          href={`${getBasePath()}/api/auth/login/?returnTo=${getBasePath()}/dash/?login=true`}
          className="flex items-center space-x-1"
        >
          <ButtonWithIcon
            icon={{
              icon: 'userCircle',
              size: 24,
              colorType: 'base',
              colorShade: 500,
            }}
          >
            Sign In
          </ButtonWithIcon>
        </a>
      ) : (
        <>
          {!isShelter && (
            <>
              {featureFlags?.includes(flagPersistentSearch) && (
                <Notifications />
              )}
              <div data-testid="navbar-messages">
                <AnimatedIcon
                  iconType="message"
                  href="/dash/messages"
                  srText="Messages"
                  showAnimation={hasMessage}
                  data-testid={
                    hasMessage ? 'navbar-has-messages' : 'navbar-no-messages'
                  }
                />
              </div>
            </>
          )}
          <DropDownMenu
            icon={dropDownIcon}
            items={isShelter ? shelterLinks : userLinks}
            menuClasses="-mr-6 md:mr-0 mt-3.5 md:mt-4 lg:mt-[18px] xl:mt-5 z-[3]"
          />
        </>
      )}
    </div>
  );
}
