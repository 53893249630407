import { IconType, chooseIcon } from '@/components/atoms/Icon/Icon';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

/**
 * IAnimatedIcon
 *
 * @interface
 */
export interface IAnimatedIcon
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  /** The icon to display */
  iconType: IconType;
  /** The srText to display */
  srText?: string;
  /** The showAnimation is true if the animation should be shown */
  showAnimation?: boolean;
  /** The href to link to */
  href?: string;
  /** The classes to apply to the icon */
  className?: string;
  /** The classes to apply to the animation */
  animationClassName?: string;
}

/**
 * AnimatedIcon A message icon with an alert animation
 *
 * @param {IAnimatedIcon} props - The props for the AnimatedIcon component
 * @returns {React.FC<IAnimatedIcon>} Component
 */
const AnimatedIcon: React.FC<IAnimatedIcon> = ({
  iconType,
  srText,
  showAnimation = false,
  href,
  className,
  animationClassName,
  ...rest
}) => {
  const SelectedIcon = chooseIcon(iconType);

  const renderChildren = (
    <>
      <span
        className={
          showAnimation
            ? 'text-base-300 hover:text-base-400'
            : 'text-neutral-800 hover:text-base-300'
        }
      >
        <SelectedIcon color="currentColor" size={24} />
      </span>
      {srText && <span className="sr-only">{srText}</span>}
      {showAnimation && (
        <span
          data-testid="animated-icon-animation"
          className={twMerge(
            'w-2 h-2 ml-px mt-0.5 rounded-full border border-base-300 animate-pulse-bg-step-medium from-base-200 to-base-400 absolute right-0 -top-0.5 group-hover:animate-none group-hover:bg-base-200 group-hover:border-base-400',
            animationClassName
          )}
        ></span>
      )}
    </>
  );

  return (
    <div data-testid="animated-icon" className={className}>
      {href ? (
        <Link href={href}>
          <a className="inline-flex relative group" data-testid="animated-icon-anchor" {...rest}>
            {renderChildren}
          </a>
        </Link>
      ) : (
        <button className="inline-flex relative group" data-testid="animated-icon-button" {...rest}>
          {renderChildren}
        </button>
      )}
    </div>
  );
};

export default AnimatedIcon;
