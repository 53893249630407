import { ColorShade, ColorType } from '@/components/atoms/Colors/Colors';
import Icon, { IIcon } from '@/components/atoms/Icon/Icon';
import { Menu } from '@headlessui/react';
import Link from 'next/link';
import React from 'react';

/**
 * IMenuItem
 *
 * @interface
 */
export interface IMenuItem {
  /** The text to display. */
  name: string;
  /** The url to display */
  url: string;
  /** Whether the menu item is active or not. */
  active?: boolean;
  /** The optional target of the link. */
  target?: string;
  /** The optional close function for the link. */
  close?: () => void;
  /** Whether the item is a destructive action */
  destructive?: boolean;
}

/**
 * IMenuButton
 *
 * @interface
 */
export interface IMenuButton {
  /** The text to display. */
  name: string;
  /** The on click handler. */
  onClick: () => void;
  /** Whether the item is a destructive action */
  destructive?: boolean;
}

/**
 * MenuLink This Next Link ref is used to create a link for the menu item. The
 * Next Link element can not be used inside of the headless ui menu component.
 */
const MenuLink = React.forwardRef<HTMLAnchorElement, IMenuItem>(
  (props, ref) => {
    const { url, name, active, destructive, close, ...rest } = props;
    return (
      <div
        onClick={() => close && close()}
        data-testid="menulink-clickable"
        className="w-full"
      >
        <Link href={url}>
          <a
            ref={ref}
            {...rest}
            className={[
              'w-full flex text-body4',
              destructive && '!text-error-200 hover:text-error-300',
              active ? 'text-base-300' : 'text-neutral-800',
            ].join(' ')}
          >
            {name}
          </a>
        </Link>
      </div>
    );
  }
);
MenuLink.displayName = 'MenuLink';

/**
 * IDropDownIcon
 *
 * @interface
 */
export interface IDropDownIcon extends IIcon {
  /** The color type of the icon when active or hovered */
  activeColorType: ColorType;
  /** The color shade of the icon when active or hovered */
  activeColorShade: ColorShade;
}

/**
 * IDropDownMenu
 *
 * @interface
 */
export interface IDropDownMenu {
  /** The items to display. */
  items: Array<IMenuItem | IMenuButton>;
  /** Icon to display for the menu. */
  icon: IDropDownIcon;
  /** The classes for the menu. */
  menuClasses?: string;
}

/**
 * Drop Down Menu The Drop Down Menu Component that renders a drop down menu.
 *
 * @param {IDropDownMenu} props - The props for the Drop Down Menu Component
 * @returns {React.FC<IDropDownMenu>} Drop Down Menu Component
 */
const DropDownMenu: React.FC<IDropDownMenu> = ({
  items,
  menuClasses,
  icon,
}) => {
  const [iconHover, setIconHover] = React.useState<boolean>(false);

  return (
    <Menu
      as="div"
      data-testid="drop-down-menu"
      className="relative inline-block"
    >
      {({ open }) => (
        <>
          <Menu.Button
            data-headlessui-state="open"
            data-testid="drop-down-menu-button"
            className="flex align-center"
            onMouseEnter={() => setIconHover(true)}
            onMouseLeave={() => setIconHover(false)}
          >
            <Icon
              data-testid="drop-down-menu-icon"
              icon={icon.icon}
              size={icon.size}
              colorType={
                open || iconHover ? icon.activeColorType : icon.colorType
              }
              colorShade={
                open || iconHover ? icon.activeColorShade : icon.colorShade
              }
            />
          </Menu.Button>
          <Menu.Items
            className={`${menuClasses} w-[222px] bg-neutral-100 absolute right-0 py-3.5 drop-shadow-[0_2px_8px_rgba(0,0,0,0.04)]`}
          >
            {items.map((item) => (
              <div key={item.name} className="w-full">
                {'url' in item && (
                  <Menu.Item
                    as="div"
                    className="w-full hover:text-base-300 cursor-pointer px-5 py-1.5"
                    data-testid={`drop-down-menu-${item.name}`}
                  >
                    {({ active, close }) => (
                      <MenuLink
                        url={item.url}
                        name={item.name}
                        active={active}
                        close={close}
                        destructive={item.destructive}
                      />
                    )}
                  </Menu.Item>
                )}
                {'onClick' in item && (
                  <Menu.Button
                    className="focus:text-base-300 hover:text-base-300 text-neutral-800 text-body4 w-full text-left px-5 py-1.5"
                    onClick={() => item.onClick()}
                  >
                    {item.name}
                  </Menu.Button>
                )}
              </div>
            ))}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default DropDownMenu;
